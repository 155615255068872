import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { VisitReport } from '../models/visitReport.models';
import { Visit } from '../models/visited.models';
import { VisitGetaway } from '../ports/visited.getaway';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class VisitService implements VisitGetaway {
  constructor(protected http: HttpClient) {}

  pushVisit(visitName: string, numberOfVisit: number) {
    this.http
      .post<Visit>(`${environment.apiURL}visited`, {
        visitName,
        numberOfVisit,
      })
      .subscribe();
  }

  getVisits(): Observable<VisitReport[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true, // This is the crucial part
    };
    return this.http
      .get<VisitReport[]>(`${environment.apiURL}admin/graph`, httpOptions)
      .pipe();
  }
}
